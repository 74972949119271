(function () {
    'use strict';

    angular
        .module('supplyApp')
        .controller('PaytsByClassesController', PaytsByClassesController);

    PaytsByClassesController.$inject = ['$scope', '$state', 'Report', 'ReportConfig', 'ParseLinks', 'AlertService', 'Principal', 'School', 'entity'];

    function PaytsByClassesController($scope, $state, Report, ReportConfig, ParseLinks, AlertService, Principal, School, entity) {
        var vm = this;

        vm.filter = {};
        vm.schoolId = null;
        vm.schoolValid = false;

        vm.search = doReport;
        vm.resetSearch = doReset;
        vm.filterConfig = ReportConfig.filterConfig;

        vm.showSchoolLink = Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_BASE_ACC', 'ROLE_BASE_ADMIN']);

        init();

        function init() {
            vm.filter = ReportConfig.getPeriod();
            vm.schoolId = initSchoolId();
            vm.schoolValid = !!vm.schoolId;

            if (vm.schoolId && vm.showSchoolLink) {
                vm.school = School.get({id: vm.schoolId});
            }
        }

        function initSchoolId() {
            var schoolId = entity ? entity.id : Principal.current().school ? Principal.current().school.id : null;
            var canSelectMore = Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_BASE_ACC', 'ROLE_BASE_ADMIN']);

            if (!schoolId) {
                vm.schoolRequired = true;
                vm.schools = School.all();
                schoolId = ReportConfig.getSchool();
            } else if (canSelectMore) {
                vm.schoolRequired = true;
                vm.schools = School.all();
            }

            return schoolId;
        }

        function doReport() {
            if (!vm.schoolValid) {
                return;
            }
            Report.clazzes({
                id: vm.schoolId,
                search: vm.filter,
            }, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.report = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function doReset() {
            vm.report = null;
            ReportConfig.reset();
            init();
        }

        $scope.$watch('vm.schoolId', function (newValue) {
            vm.schoolValid = !!newValue;
            if (newValue) {
                ReportConfig.setSchool(newValue);
                doReport();
            } else {
                vm.report = null;
            }
        });
    }

})();
