(function() {
    'use strict';

    angular
        .module('supplyApp.common')
        .factory('ReportConfig', ReportConfig);

    function ReportConfig() {
        var periodFilter, school;
        var service = {
            reset: init,
            setPeriod : function (val) {periodFilter = val;},
            getPeriod : function () {return periodFilter;},
            setSchool : function (val) {school = val;},
            getSchool : function () {return school;},
            filterConfig : {
                items : [
                    {key:"month", label: "За текущий месяц"},
                    {key:"prev_month", label : "За прошлый месяц"},
                    {key:"week", label : "За последние две недели"},
                ],
                custom: true,
            },
        };

        function init() {
            periodFilter = {};
            periodFilter.period = 'month';
            school = null;
        }

        init();
        return service;
    }


})();
