(function() {
    'use strict';

    angular
        .module('supplyApp')
        .controller('PaytsBySchoolsController', PaytsBySchoolsController);

    PaytsBySchoolsController.$inject = ['$scope', '$state', 'Report', 'ReportConfig', 'ParseLinks', 'AlertService'];

    function PaytsBySchoolsController ($scope, $state, Report, ReportConfig, ParseLinks, AlertService) {
         var vm = this;

        vm.search = doReport;
        vm.resetSearch = doReset;
        vm.filterConfig = ReportConfig.filterConfig;

        function init() {
            vm.filter = ReportConfig.getPeriod();
            doReport();
        }

        function doReport () {
            Report.schools({
                search: vm.filter,
            }, onSuccess, onError);
            function onSuccess(data, headers) {
                vm.report = data;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function doReset () {
            vm.report = null;
            ReportConfig.reset();
            init();
        }

        init();
    }
})();
